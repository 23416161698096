import { useEffect } from 'react'

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Account } from 'pages/Admin/Account'
import { Users } from 'pages/Admin/Users'
import { Support } from './Admin/Support'

import { SurveyReportViewer } from 'components/PDF/SurveyReportViewer'
import { Integrations } from './Admin/Integrations'
import { SurveyDetails } from './Engage/SurveyDetails'
import { Shenanigans } from './Playground/Shenanigans'
import { Workspaces } from './Workspaces/Workspaces'

export const Dashboard = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname.indexOf('/engage/') !== -1) {
      navigate(location.pathname.replaceAll('/engage/', '/forms/'))
    }
  }, [location, navigate])

  return (
    <Panel>
      {/* <Sidebar /> */}
      <Content>
        <Routes>
          <Route path="/workspaces" element={<Workspaces />} />
          <Route path="/forms/:surveyId/*" element={<SurveyDetails />} />
          <Route path="/forms/reports/:surveyId" element={<SurveyReportViewer />} />
          <Route path="/playground" element={<Shenanigans />} />

          <Route path="/account/*" element={<Account />} />
          <Route path="/users" element={<Users />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/support" element={<Support />} />

          {/* default route */}
          <Route path="/*" element={<Navigate to="/dashboard/workspaces" />} />
        </Routes>
      </Content>
    </Panel>
  )
}

const Panel = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const Content = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  padding-top: 50px;
  width: 100%;
  overflow-y: auto;
`

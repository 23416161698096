import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AuthenticationDetails, CognitoUser, CognitoUserPool, ISignUpResult } from 'amazon-cognito-identity-js'

const poolData = {
  // bwi-dev
  UserPoolId: 'us-east-1_9PSMG37dp',
  ClientId: '7dms5nf6tj04gh9m5k87lqhc8p',

  // bwi-prod
  // UserPoolId: 'us-east-1_6ThpuDHkj',
  // ClientId: '7migcl7itvc8d9jk5cr469hlvn'

  // yaynay (legacy)
  // UserPoolId: 'us-east-1_hOvMBHmZy',
  // ClientId: '2fdajtlodp40ni7tlmq08djma9',
}

const userPool = new CognitoUserPool(poolData)

export interface AuthState {
  authenticated: boolean
  existingUserError: boolean
  forgotPassword: boolean
  jwtToken: string
  loginPage: 'signUp' | 'signIn' | 'forgotPassword' | 'resetPassword' | 'confirmEmail'
  recoveryAddress: string
  recoveryUsername: string
  role: string
  status: 'idle' | 'loading' | 'failed'
  userId: string
  username?: string
  password?: string
  firstName?: string
  lastName?: string
}

const initialState: AuthState = {
  authenticated: false,
  existingUserError: false,
  forgotPassword: false,
  jwtToken: '',
  loginPage: 'signIn',
  recoveryAddress: '',
  recoveryUsername: '',
  role: 'none',
  status: 'loading',
  userId: '',
  firstName: '',
  lastName: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    forgotPasswordFlowStarted: (state) => {
      state.loginPage = 'forgotPassword'
    },
    forgotPasswordEmailSent: (state) => {
      state.loginPage = 'resetPassword'
    },
    forgotPasswordFlowEnded: (state) => {
      state.loginPage = 'signIn'
      state.recoveryAddress = ''
    },
    signUpFlowStarted: (state) => {
      state.loginPage = 'signUp'
    },
    signUpFlowCanceled: (state) => {
      state.loginPage = 'signIn'
    },
    clearCredentials: (state) => {
      state.password = undefined
      state.username = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetUser.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncGetUser.fulfilled, (state, action) => {
        state.status = 'idle'
        state.authenticated = !!action.payload.jwtToken
        state.jwtToken = action.payload.jwtToken || ''
        state.role = action.payload.role || 'none'
      })
      .addCase(asyncGetUser.rejected, (state) => {
        state.status = 'idle'
        state.authenticated = false
        state.jwtToken = ''
        state.role = 'none'
      })
      .addCase(asyncSignIn.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncSignIn.fulfilled, (state, action) => {
        state.status = 'idle'
        state.authenticated = true
        state.jwtToken = action.payload.jwtToken || ''
        state.loginPage = 'signIn'
      })
      .addCase(asyncSignIn.rejected, (state) => {
        state.status = 'failed'
        state.loginPage = 'signIn'
      })
      .addCase(asyncSignUp.fulfilled, (state, action) => {
        state.username = action.payload.username
        state.password = action.payload.password
        state.firstName = action.payload.firstName
        state.lastName = action.payload.lastName
        state.loginPage = 'confirmEmail'
      })
      .addCase(asyncChangePassword.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncChangePassword.fulfilled, (state) => {
        state.status = 'idle'
      })
      .addCase(asyncForgotPassword.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncForgotPassword.fulfilled, (state, action) => {
        state.status = 'idle'
        state.forgotPassword = false
        state.recoveryUsername = action.payload.username
        state.recoveryAddress = action.payload.address as string
      })
      .addCase(asyncForgotPassword.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(asyncConfirmCodeUpdatePassword.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncConfirmCodeUpdatePassword.fulfilled, (state) => {
        state.status = 'idle'
        state.recoveryAddress = ''
      })
      .addCase(asyncSignOut.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncSignOut.fulfilled, (state, action) => {
        state.status = 'idle'
        state.authenticated = false
        state.jwtToken = ''
      })
  },
})

export const asyncGetUser = createAsyncThunk<{ jwtToken?: string; role?: string }>('auth/getUser', async () => {
  const { jwtToken, role } = await getUser()
  return { jwtToken, role }
})

export const asyncSignUp = createAsyncThunk<
  { success?: boolean; userId?: string; username: string; password: string; firstName: string; lastName: string },
  { username: string; password: string; firstName: string; lastName: string }
>('auth/signUp', async (credentials) => {
  const { username, password, firstName, lastName } = credentials
  const { success } = await signUp(username, password)
  if (!success) throw new Error('sign up flow failed')
  return { success, username, password, firstName, lastName }
})

export const asyncConfirmCode = createAsyncThunk<
  { success: boolean; throttled?: boolean },
  { code: string; username: string }
>('auth/confirmCode', async (credentials) => {
  const { code, username } = credentials
  const { success, throttled } = await confirmCode(code, username)
  if (!success) {
    throw new Error(
      throttled
        ? 'Attempt limit exceeded. Please wait before trying again.'
        : 'Password reset failed. Please check your verification code.'
    )
  }
  return { success }
})

/*
 * Resends confirmation/verification code to user's email. Only works for code sent
 * to confirm email address. For password reset codes, use asyncForgotPassword.
 */
export const asyncResendCode = createAsyncThunk<{ success: boolean }, { username: string }>(
  'auth/resendCode',
  async ({ username }) => {
    const { success, throttled } = await resendCode(username)
    if (!success) {
      throw new Error(
        throttled
          ? 'Attempt limit exceeded. Please wait before trying again.'
          : 'Failed to resend code. Please contact us if this issue persists.'
      )
    }
    return { success }
  }
)

export const asyncSignIn = createAsyncThunk<{ jwtToken?: string }, { username: string; password: string }>(
  'auth/signIn',
  async (credentials) => {
    const { username, password } = credentials
    const { success, jwtToken } = await signIn(username, password)
    if (!success) throw new Error('authentication failed')
    return { jwtToken }
  }
)

export const asyncSignOut = createAsyncThunk<{ success: boolean }>('auth/signOut', async () => {
  const { success } = await signOut()
  return { success }
})

export const asyncChangePassword = createAsyncThunk<
  { success: boolean },
  { username: string; oldPassword: string; newPassword: string }
>('auth/chnagePassword', async ({ username, oldPassword, newPassword }) => {
  // need to return password from this method so we can pass it back to the API
  // for consumption by the API cserver consuming the rest of the fielids
  const { success } = await changePassword(username, oldPassword, newPassword)
  return { success }
})

export const asyncForgotPassword = createAsyncThunk<{ address?: string; username: string }, { username: string }>(
  'auth/forgotPassword',
  async (credentials) => {
    const { username } = credentials
    const { success, address, throttled } = await forgotPassword(username)
    if (!success) {
      throw new Error(
        throttled
          ? 'Attempt limit exceeded. Please wait before trying again.'
          : 'Password reset failed. Please check your verification code.'
      )
    }
    return { address, username }
  }
)

export const asyncConfirmCodeUpdatePassword = createAsyncThunk<
  { success: boolean },
  { username: string; code: string; newPassword: string }
>('auth/confirmCodeUpdatePassword', async (credentials) => {
  const { username, code, newPassword } = credentials
  const { success, throttled } = await confirmCodeUpdatePassword(username, code, newPassword)
  if (!success) {
    throw new Error(
      throttled
        ? 'Attempt limit exceeded. Please wait before trying again.'
        : 'Password reset failed. Please check your verification code.'
    )
  }
  return { success }
})

const getUser = () => {
  return new Promise<{ jwtToken?: string; role?: string }>((resolve, reject) => {
    const user = userPool.getCurrentUser()
    if (!user) {
      // resolve({ jwtToken: undefined, role: undefined })
      return reject('no authenticated user')
    }
    user.getSession((err: any, result: any) => {
      if (err) {
        console.error(err)
        return reject(err)
      }
      resolve({ jwtToken: result.getIdToken().getJwtToken(), role: result.getIdToken().payload['custom:role'] })
    })
  })
}

const signUp = (username: string, password: string) => {
  return new Promise<{ success: boolean }>((resolve) => {
    userPool.signUp(username, password, [], [], (error?: Error, result?: ISignUpResult) => {
      if (error || !result) return resolve({ success: false })
      return resolve({ success: true })
    })
  })
}

const confirmCode = (code: string, username: string) => {
  return new Promise<{ success: boolean; throttled?: boolean }>((resolve) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (result === 'SUCCESS') {
        return resolve({ success: true })
      } else if (err) {
        console.error(err)
        return resolve({ success: false, throttled: err?.name === 'LimitExceededException' })
      }
      // @todo - add more error handling logic
      else return resolve({ success: false })
    })
  })
}

const resendCode = (username: string) => {
  return new Promise<{ success: boolean; throttled?: boolean }>((resolve) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.error(err)
        return resolve({ success: false, throttled: err?.name === 'LimitExceededException' })
      }
      return resolve({ success: true })
    })
  })
}

const signIn = (username: string, password: string) => {
  return new Promise<{ success: boolean; jwtToken?: string }>((resolve) => {
    const authenticationDetails = new AuthenticationDetails({ Username: username, Password: password })
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const jwtToken = result.getIdToken().getJwtToken()
        return resolve({ success: true, jwtToken: jwtToken })
      },
      onFailure: (err) => {
        return resolve({ success: false })
      },
    })
  })
}

const signOut = () => {
  return new Promise<{ success: boolean }>((resolve) => {
    const user = userPool.getCurrentUser()
    if (!user) return resolve({ success: true })
    user.signOut(() => resolve({ success: true }))
  })
}

const changePassword = (username: string, oldPassword: string, newPassword: string) => {
  return new Promise<{ success: boolean }>((resolve) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
    cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
      return resolve({ success: !error && result === 'SUCCESS' })
    })
  })
}

const forgotPassword = (username: string) => {
  return new Promise<{ success: boolean; address?: string; throttled?: boolean }>((resolve) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        resolve({ success: true, address: result.CodeDeliveryDetails.Destination })
      },
      onFailure: (err) => {
        console.error(err)
        resolve({ success: false, throttled: err?.name === 'LimitExceededException' })
      },
    })
  })
}

const confirmCodeUpdatePassword = (username: string, code: string, newPassword: string) => {
  return new Promise<{ success: boolean; throttled?: boolean }>((resolve) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: (result) => {
        resolve({ success: true })
      },
      onFailure: (err) => {
        console.error(err)
        resolve({ success: false, throttled: err?.name === 'LimitExceededException' })
      },
    })
  })
}

export const {
  forgotPasswordFlowStarted,
  forgotPasswordEmailSent,
  forgotPasswordFlowEnded,
  signUpFlowStarted,
  signUpFlowCanceled,
  clearCredentials,
} = authSlice.actions

import { Edit, Email, Password, PersonRemove, PhonelinkLock } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { Toggle } from 'components/Input/Toggle'
import { useRef } from 'react'
import { useGetUserQuery } from 'services/userApi'
import styled from 'styled-components/macro'
import usePageTitle from 'utils/usePageTitle'

export const Profile = () => {
  const { data: user, isLoading: userLoading } = useGetUserQuery()

  const onChangeEmailClick = () => {
    // todo: open change email dialog
    alert('Coming soon!')
  }

  const onChangePasswordClick = () => {
    // todo: open change password dialog
    alert('Coming soon!')
  }

  const onDeleteAccountClick = () => {
    // todo: open confirmation dialog
    alert('Coming soon!')
  }

  const toggleRef = useRef(null)

  const onToggle2FA = () => {
    setTimeout(() => {
      alert('Coming soon!')
      if (toggleRef.current) {
        toggleRef.current.checked = !toggleRef.current?.checked
      }
    }, 250)
  }

  usePageTitle('Account | Storyforms')

  return (
    <>
      {userLoading ? (
        <SystemMessage>Loading...</SystemMessage>
      ) : !user ? (
        <SystemMessage>Error loading profile</SystemMessage>
      ) : (
        <StyledProfile>
          <Flex direction="row" align="center" style={{ marginBottom: 40 }}>
            {/* <Avatar src={user.avatarUrl} /> */}
            <Flex direction="column" justify="center">
              <AccountName>
                <span style={{ fontSize: 28 }}>
                  {user.firstName} {user.lastName}
                </span>
                <span>
                  <Tooltip title="Edit name">
                    <Edit
                      sx={{ fontSize: 16, marginLeft: '5px', color: '#888', cursor: 'pointer' }}
                      onClick={() => alert('Coming soon!')}
                    />
                  </Tooltip>
                </span>
              </AccountName>
              <AccountEmail>{user.email}</AccountEmail>
            </Flex>
          </Flex>

          <SectionTitle>Security</SectionTitle>
          <Section>
            <Card>
              <Email />
              <CardTitle>Email</CardTitle>

              <ButtonWrapper>
                <Button
                  onClick={onChangeEmailClick}
                  size="large"
                  sx={{ textTransform: 'capitalize' }}
                  variant="text"
                >
                  Change
                </Button>
              </ButtonWrapper>
            </Card>
            <Card>
              <PasswordIcon />
              <CardTitle>Password</CardTitle>

              <ButtonWrapper>
                <Button
                  onClick={onChangePasswordClick}
                  size="large"
                  sx={{ textTransform: 'capitalize' }}
                  variant="text"
                >
                  Change
                </Button>
              </ButtonWrapper>
            </Card>
            <Card>
              <PhonelinkLock />
              <CardTitle>Two-factor authentication</CardTitle>

              <ButtonWrapper>
                <Toggle ref={toggleRef} type="checkbox" style={{ width: 60, height: 32 }} onClick={onToggle2FA} />
              </ButtonWrapper>
            </Card>
          </Section>

          <SectionTitle>Danger Zone</SectionTitle>
          <Section>
            <Card style={{ maxWidth: 300 }}>
              <PersonRemove color="error" />
              <CardTitle>Delete account</CardTitle>
              <CardText>
                Permanently delete your account, including all Storyforms you've created. This cannot be undone.
              </CardText>

              <ButtonWrapper>
                <Button
                  onClick={onDeleteAccountClick}
                  size="large"
                  sx={{ textTransform: 'capitalize', marginTop: '20px' }}
                  variant="text"
                  color="error"
                >
                  Delete
                </Button>
              </ButtonWrapper>
            </Card>
          </Section>
        </StyledProfile>
      )}
    </>
  )
}

const StyledProfile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 80px auto;
  width: max-content;
`

const Flex = styled.div<{ direction?: string; justify?: string; align?: string }>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
`

const Avatar = styled.div<{ src?: string }>`
  margin: 0 15px 0 0;
  background-image: ${({ src }) => 'url(' + (src || '/img/image_handling/add_image.png') + ')'};
  background-repeat: no-repeat;
  background-size: 120px;
  background-position: -20px -55px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  cursor: pointer;
`

const AccountName = styled.p`
  font-size: 18px;
`

const AccountEmail = styled.p`
  margin-top: 4px;
  font-size: 14px;
  color: #555;
`

const SystemMessage = styled.p`
  margin: 80px auto;
  text-align: center;
  font-size: 18px;
  color: #888;
`

const SectionTitle = styled.p`
  font-size: 20px;
  margin: 30px 0;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const ButtonWrapper = styled.div`
  color: #0d83d9;
  margin-top: auto;
  align-self: center;
  place-self: center;
  cursor: pointer;
  font-weight: 400;
  /* text-decoration: underline; */
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 150px;
  min-height: 140px;
  padding: 20px;
  border-radius: 2px;
  background: white;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  margin: 0 20px 20px 0;
`

const CardTitle = styled.p`
  font-size: 16px;
  color: #333;
  margin: 10px 0;
  cursor: default;
`

const CardText = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #555;
`

const PasswordIcon = styled(Password)``

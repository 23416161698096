import { Add, Group, Person } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import { ContextMenu, ContextMenuItem } from 'components/ContextMenu'
import { useState } from 'react'
import { useDeleteWorkspaceMutation } from 'services/workspaceApi'
import styled from 'styled-components/macro'
import { useKey } from 'utils/useKey'

export const WorkspaceList = ({
  visibility,
  workspaces,
  selectedWorkspace,
  setSelectedWorkspace,
  onCreateWorkspace,
  onMoveExperience,
}: {
  visibility: string
  workspaces: any[]
  selectedWorkspace?: any
  setSelectedWorkspace?: any
  onCreateWorkspace?: any
  onMoveExperience?: any
}) => {
  const [deleteWorkspace] = useDeleteWorkspaceMutation()

  const [creatorOpen, setCreatorOpen] = useState(false)
  const [creatorNameInput, setCreatorNameInput] = useState('')
  const [dragHoveredWorkspace, setDragHoveredWorkspace] = useState<Workspace | undefined>()

  useKey('Escape', () => setCreatorOpen(false))

  return (
    <StyledWorkspaceList>
      <WorkspaceListHeader>
        <WorkspaceListTitle>
          <span style={{ verticalAlign: 'middle', display: 'inline-block', marginRight: 5, marginLeft: 15 }}>
            {visibility === 'private' ? <Person sx={{ width: 20 }} /> : <Group sx={{ width: 20 }} />}
          </span>
          <span style={{ verticalAlign: 'middle', display: 'inline-block' }}>
            <span style={{ textTransform: 'capitalize' }}>{visibility}</span> workspaces
          </span>
        </WorkspaceListTitle>

        {visibility === 'private' && (
          <IconButton
            sx={{ marginRight: '5px' }}
            onClick={() => {
              onCreateWorkspace({ name: 'Untitled Workspace', visibility })
            }}
          >
            <Add />
          </IconButton>
        )}
      </WorkspaceListHeader>

      <WorkspaceListItems>
        {workspaces.map((workspace, index) => (
          <ContextMenu
            key={`${visibility}-li-${index}`}
            items={
              <>
                <ContextMenuItem
                  type="dangerous"
                  action={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to delete this workspace? This will also delete all the forms in the workspace.'
                      )
                    ) {
                      deleteWorkspace(workspace._id)
                    }
                  }}
                >
                  Delete
                </ContextMenuItem>
              </>
            }
          >
            <WorkspaceListItem
              selected={selectedWorkspace?._id === workspace._id}
              hovered={dragHoveredWorkspace?._id === workspace._id}
              onClick={() => setSelectedWorkspace(workspace)}
              onDrop={() => {
                onMoveExperience(workspace)
                setDragHoveredWorkspace(undefined)
              }}
              onDragEnter={() => {
                setDragHoveredWorkspace(workspace)
              }}
              onDragLeave={() => {
                setDragHoveredWorkspace(undefined) // when dragging between two, dragLeave will always fire before dragEnter
              }}
              onDragOver={(event) => event.preventDefault()}
            >
              <WorkspaceListItemLabel>{workspace.name}</WorkspaceListItemLabel>
              <WorkspaceListItemLabel style={{ color: '#777' }}>
                {workspace.items?.length || 0}
              </WorkspaceListItemLabel>
            </WorkspaceListItem>
          </ContextMenu>
        ))}
      </WorkspaceListItems>
    </StyledWorkspaceList>
  )
}

const StyledWorkspaceList = styled.div``

const WorkspaceListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const WorkspaceListItems = styled.div``

const WorkspaceListTitle = styled.div`
  font-size: 16px;
  color: #555;
`

const WorkspaceListItem = styled.div<{ hovered?: boolean; selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.2s all ease;

  ${({ hovered, selected }) =>
    hovered && !selected
      ? `
    padding: 10px 30px 10px 10px;
    background-color: #5faadc20;
  `
      : `
    padding: 10px 20px;
    background-color: transparent;
  `}

  ${({ selected }) => (selected ? 'background-color: #5faadc60 !important;' : '')}


  &:hover {
    background-color: #5faadc20;
  }
`

const WorkspaceListItemLabel = styled.p``

const CreatorOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  width: 400px;
  height: max-content;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
  overflow: visible;
`

const CreatorTitle = styled.p`
  margin: 10px;
  font-size: 18px;
  padding-bottom: 20px;
  border-bottom: 1px solid #7773;
  user-select: none;
`

const CreatorInput = styled.input`
  margin: 10px;
  padding: 6px 5px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 20px;
`

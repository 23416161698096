import { Tooltip } from '@mui/material'
import GaugeChart from 'lib/GaugeChart'
import styled from 'styled-components/macro'

export const ExperienceMetrics = ({ experience, style }: { experience: any; style?: any }) => {
  const survey = experience as Survey

  if (!survey.anonymousVoting || !survey.anonymousVoting.enabled || !survey.anonymousVoting.counts) {
    return <p>Metrics not supported for this Storyform</p>
  }

  let metrics = []
  let view = survey.anonymousVoting.counts.view || 0
  let start = survey.anonymousVoting.counts.start || 0
  let startPct = Math.round((start * 1000.0) / (view || 1)) / 10
  let completion = survey.anonymousVoting.counts.completion || 0
  let completionPct = Math.round((completion * 1000.0) / (start || 1)) / 10

  // metrics.push({
  //   metric:'Questions',
  //   value: survey.questions.length
  // })

  // if (!survey.anonymousVoting && survey.anonymousVoting.counts) {
  //   return null
  // }
  // let anon = survey.anonymousVoting.counts

  metrics.push(
    {
      metric: 'Unique views',
      value: view,
      description: '',
    },
    {
      metric: 'Unique starts',
      value: start,
      description: '',
    },
    // {
    //   metric: 'Start rate',
    //   value: view === 0 ? 'N/A' : startPct + '%',
    //   description:
    //     'The percentage of people who started the Storyform (ie. voted on the first question) after viewing it',
    // },
    {
      metric: 'Completions',
      value: completion,
      description: 'The number of people who answered all questions in the Storyform',
    }
    // {
    //   metric: 'Completion rate',
    //   value: start === 0 ? 'N/A' : completionPct + '%',
    //   description: 'The percentage of people who finished the Storyform after starting it',
    //   tooltipPosition: 'left',
    // }
  )

  // , {
  //   metric:'CTA click rate',
  //   value: completion === 0 || !survey.callToAction || ['donate', 'card'].indexOf(survey.callToAction.type) === -1 ? 'N/A' : (Math.round(ctaClick * 1000.0 / completion) / 10) + '%',
  //   description: 'The number of unique clicks on the call-to-action link at the end of the Storyform (Donate and Card CTA modes only)',
  //   tooltipPosition: 'left'
  // }

  let basicMetrics = metrics.map((metric, index) => (
    <ExperienceMetric key={metric.metric + index}>
      <MetricValue>{metric.value}</MetricValue>
      <MetricLabel>
        {metric.description ? (
          <Tooltip
            style={{
              padding: 10,
              width: 260,
              textDecoration: 'underline 1.5px dotted rgb(170, 170, 170)',
              textUnderlinePosition: 'under',
            }}
            title={metric.description}
            placement={metric.tooltipPosition}
          >
            {metric.metric}
          </Tooltip>
        ) : (
          metric.metric
        )}
      </MetricLabel>
    </ExperienceMetric>
  ))

  return (
    <StyledExperienceMetrics style={style}>
      <GaugeCharts>
        <GaugeChart
          percent={startPct / 100}
          nrOfLevels={40}
          // arcWidth={0.3}
          arcPadding={0}
          cornerRadius={0}
          colors={['#71ceff', '#71ffd1']}
          hideText={false}
          textColor={'#555'}
          needleColor={'#888'}
          label={'Start rate'}
          fontSize={'58'}
          labelFontSize={'30'}
          style={{ width: 500, background: '#fff', zoom: 0.6 }}
        />
        <GaugeChart
          id="prerender-gauge-charts"
          percent={completionPct / 100}
          nrOfLevels={40}
          // arcWidth={0.3}
          arcPadding={0}
          cornerRadius={0}
          colors={['#71ceff', '#71ffd1']}
          hideText={false}
          textColor={'#555'}
          needleColor={'#888'}
          label={'Completion rate'}
          fontSize={'58'}
          labelFontSize={'30'}
          style={{ width: 500, background: '#fff', zoom: 0.6 }}
        />
      </GaugeCharts>
      <BasicMetrics>{basicMetrics}</BasicMetrics>
    </StyledExperienceMetrics>
  )
}

const StyledExperienceMetrics = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
`

const GaugeCharts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`

const BasicMetrics = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`

const ExperienceMetric = styled.div`
  margin: 20px;
`

const MetricValue = styled.div`
  text-align: center;
  color: #002868;
  font-weight: normal;
  margin: 0 0 5px 0;
  font-size: 24px;
`

const MetricLabel = styled.div`
  margin: 0;
  text-align: center;
`

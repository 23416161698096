import { ArrowForwardRounded } from '@mui/icons-material'
import { SearchIcon } from 'components/Icons/icons'
import { Popup } from 'components/Popup'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRunMixedSearchMutation } from 'services/miscApi'
import styled from 'styled-components/macro'
import { useDebounce } from 'utils/useDebounce'
import { useKey } from 'utils/useKey'

const MIN_QUERY_LENGTH = 3

export const MixedSearch = ({ onSelectWorkspace, onClose }: { onSelectWorkspace?: any; onClose?: any }) => {
  const [searchInput, setSearchInput] = useState('')
  const [workspaceSearchResults, setWorkspaceSearchResults] = useState<Partial<Workspace>[]>([])
  const [moduleSearchableUnitResults, setModuleSearchableUnitResults] = useState<any[]>([])

  const navigate = useNavigate()

  const [runMixedSearch, { isError: mixedSearchError, data: mixedSearchResults }] = useRunMixedSearchMutation()

  useKey('Escape', () => onClose())

  useEffect(() => {
    if (mixedSearchResults) {
      setWorkspaceSearchResults(mixedSearchResults.workspaces)
      setModuleSearchableUnitResults(mixedSearchResults.surveys)
    }
  }, [mixedSearchResults])

  useDebounce(
    searchInput,
    () => {
      if (searchInput.trim().length >= MIN_QUERY_LENGTH) {
        runMixedSearch({ query: searchInput.trim() })
      } else {
        setWorkspaceSearchResults([])
        setModuleSearchableUnitResults([])
      }
    },
    333
  )

  return (
    <Popup
      handleBackgroundClick={onClose}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SearchOverlay>
        <SearchBar>
          <SearchIcon width="1em" height="1em" style={{ color: '#aaaaaa', padding: '10px 5px 10px 15px' }} />
          <SearchInput
            placeholder={`Name of workspace or Storyform...`}
            autoFocus={true}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </SearchBar>

        {searchInput.trim().length >= MIN_QUERY_LENGTH && (
          <SearchResultsWrapper>
            {workspaceSearchResults?.length > 0 && (
              <SearchResults>
                <SearchResultSectionLabel>Workspaces</SearchResultSectionLabel>
                {workspaceSearchResults.map((workspace, index) => (
                  <SearchResult
                    key={'ws-' + index}
                    onClick={() => {
                      onClose()
                      if (onSelectWorkspace) {
                        onSelectWorkspace(workspace)
                      }
                    }}
                  >
                    <SearchResultLabel>{workspace.name}</SearchResultLabel>
                    <SearchResultArrow />
                  </SearchResult>
                ))}
              </SearchResults>
            )}

            {moduleSearchableUnitResults?.length > 0 && (
              <SearchResults>
                <SearchResultSectionLabel>Storyforms</SearchResultSectionLabel>
                {moduleSearchableUnitResults.map((moduleSearchableUnit, index) => (
                  <SearchResult
                    key={'ms-' + index}
                    onClick={() => {
                      onClose()
                      navigate('/dashboard/forms/' + moduleSearchableUnit._id + '/design')
                    }}
                  >
                    <SearchResultLabel>{moduleSearchableUnit.name}</SearchResultLabel>
                    <SearchResultArrow />
                  </SearchResult>
                ))}
              </SearchResults>
            )}
          </SearchResultsWrapper>
        )}
      </SearchOverlay>
    </Popup>
  )
}

export const SearchButton = ({ label, onClick }: { label?: any; onClick?: any }) => {
  return (
    <StyledSearchButton onClick={onClick}>
      <SearchIcon width="1em" height="1em" style={{ color: '#aaaaaa', padding: '0.5em 0.5em 0.5em 0.5em' }} />
      <SearchLabel>{label || 'Jump to a workspace or Storyform'}</SearchLabel>
    </StyledSearchButton>
  )
}

const SearchOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  width: 500px;
  height: max-content;
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
`

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
`

const SearchInput = styled.input`
  flex: 1;
  width: max-content;
  padding-right: 10px;
  outline: none !important;
  border: none !important;
  font-size: 18px;
`

const SearchResultsWrapper = styled.div`
  box-shadow: rgb(0 0 0 / 7%) 0px 1px inset;
`

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: max-content;
  margin: 20px 0;
`

const SearchResultArrow = styled(ArrowForwardRounded)`
  opacity: 0;
  color: #555;
`

const SearchResult = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 40px;
  cursor: pointer;

  &:hover {
    background-color: #5faadc40;
  }

  &:hover ${SearchResultArrow} {
    opacity: 1;
  }
`

const SearchResultSectionLabel = styled.p`
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
`

const SearchResultLabel = styled.p`
  text-align: left;
`

const StyledSearchButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  border-radius: 4px;
  background-color: #e3e3e3;
  transition: 100ms background-color ease;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`

const SearchLabel = styled.p`
  color: #333;
  font-size: 12px;
  padding-right: 10px;
`

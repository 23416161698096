import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useGetSurveyQuery, usePutSurveyMutation } from 'services/surveyApi'

import { Edit } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import { BackButton } from 'components/BackButton'
import { NavItem, Topbar } from 'components/Elements/Topbar'
import { GrowingInput } from 'components/Input/GrowingInput'
import { Loading } from 'components/SharedPageComponents'
import { AccountMenu } from 'components/UI/AccountMenu'
import { UpgradeButton } from 'components/Upgrade'
import { useEffect, useState } from 'react'
import { useDebounce } from 'utils/useDebounce'
import usePageTitle from 'utils/usePageTitle'
import { ExperienceCanvas } from './ExperienceCanvas/'
import { ExperiencePublisher } from './ExperiencePublisher'
import { ExperienceResults } from './ExperienceResults'

export const SurveyDetails = () => {
  const { surveyId } = useParams()
  const { data: survey, isLoading: surveyLoading } = useGetSurveyQuery(surveyId || skipToken)

  const [putExperience] = usePutSurveyMutation()
  const [experienceName, setExperienceName] = useState<string>('')

  useDebounce(
    experienceName,
    () => {
      if (survey && survey.name !== experienceName) {
        putExperience({ _id: survey._id, delta: { name: experienceName } })
      }
    },
    500,
    true
  )

  useEffect(() => {
    setExperienceName(survey?.name)
  }, [survey])

  usePageTitle(`${survey?.name || 'Untitled form'} | Storyforms`)

  if (surveyLoading) {
    return <Loading>Loading...</Loading>
  }

  if (!survey) {
    return (
      <Loading style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ marginBottom: 20 }}>There's nothing here.</span>
        <BackButton backLabel={'Go back'} homeLabel={'Go back'} homePath={'/dashboard'} />
      </Loading>
    )
  }

  const basePath = `/dashboard/forms/${survey?._id}`

  return (
    <Page>
      <Topbar
        right={
          <>
            <UpgradeButton />
            <AccountMenu />
          </>
        }
        showText={false}
        left={
          <WorkspaceHeader>
            <GrowingInput
              key={survey._id}
              style={{
                fontSize: 16,
                padding: '10px 20px',
                border: 'none',
                borderRadius: 10,
                transition: '0.2s all',
                fontWeight: 600,
                width: 'max-content',
                minHeight: 16,
                lineHeight: '16px',
              }}
              // editingBackground={'#33347d10'}
              defaultValue={survey.name}
              onChange={setExperienceName}
            />
            <Tooltip title="Name your workspace" placement="bottom">
              <Edit sx={{ width: 14, marginLeft: '3px', marginRight: '8px', marginBottom: '1px' }} />
            </Tooltip>
          </WorkspaceHeader>
        }
      >
        <NavItem path={`${basePath}/design`}>design</NavItem>
        <NavItem path={`${basePath}/publish`}>publish</NavItem>
        <NavItem path={`${basePath}/results`}>results</NavItem>
      </Topbar>

      <MainArea>
        <Routes>
          <Route path="/design" element={<ExperienceCanvas experience={survey} />} />
          <Route path="/publish" element={<ExperiencePublisher experience={survey} />} />
          <Route path="/results" element={<ExperienceResults experience={survey} />} />
          <Route path="/*" element={<Navigate to={'/dashboard/forms/' + survey?._id + '/design'} />} />
        </Routes>
      </MainArea>
    </Page>
  )
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
  height: calc(100vh - 50px);
  color: #08324c;
  background-color: #f7f7f7;
  user-select: none;
  overflow-y: scroll;
  z-index: 11;
`

const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  height: 100%;
`

const ViewSelector = styled.div`
  display: flex;
  align-self: flex-end;
  background: blue;
`

const ViewType = styled.div`
  background: red;
`

const WorkspaceHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #33347d;
  font-weight: 700;
  margin-left: 10px;
  border-radius: 10px;

  &:hover {
    background: #33347d10; // 0x40 is 0.25 alpha (rgba)
    cursor: text !important;
  }
`
